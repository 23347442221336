import React from 'react'
import Wrapper from './accordionWrapper'
import Drawer from './accordionDrawer'
import Expanded from './accordionExpanded'

const Accordion = (props) => {

  return (
    <ul className={`accordion ` + props.className}>
      {React.Children.map(props.children, child => {
        return (
          <Wrapper>
            {child}
          </Wrapper>
        )
      })}
    </ul>

  )
}

Accordion.Drawer = Drawer;
Accordion.Expanded = Expanded;
Accordion.Item = ({ children }) => children

export default Accordion
