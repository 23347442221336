import React from "react"
import { Link } from "gatsby"

const StoriesOfHopeSubmenu = () => {
  return (
    <>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Devon and Leah Still's Cancer Journey" activeClassName="isActive" partiallyActive={true} to="/stories-of-hope/devon-and-leah/">Devon and Leah Still's Cancer Journey <span className="np-arrow">&rsaquo;</span></Link></li>
      {/* <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Carson's Battle with Neuroblastoma" activeClassName="isActive" partiallyActive={true} to="/stories-of-hope/carsons-battle/">Carson's Battle with Neuroblastoma <span className="np-arrow">&rsaquo;</span></Link></li> */}
    </>
  )
}

export default StoriesOfHopeSubmenu
