import React from 'react'

const expanded = ({ children }) => (
  <ul>
    <li>
      <div className="row align-justify">
        {children}
      </div>
    </li>
  </ul>
)


export default expanded
