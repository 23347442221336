import React from 'react'
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Drawer = ({ children, handleOpen, open, className }) => (
  <div>
    <button className={`accordion__button ` + className} onClick={handleOpen}>
      {children}
    </button>
    <span className="accordion__icon">
      {open &&
        <FontAwesomeIcon icon={faMinus} />
      }
      {!open &&
        <FontAwesomeIcon icon={faPlus} />
      }
    </span>
  </div>
)


export default Drawer
