import React, {useState, useEffect} from 'react'

const Wrapper = ({children}) => {

  const [open, setOpen] = useState(false)

  const handleOpen = (e) => {
    if(!open){
      setOpen(true);
    }else{
      setOpen(false);
    }
  }

  useEffect(() => {
    if(children.props.isOpen){
      setOpen(true);
    }
  },[children.props.isOpen]);

  return (
    <li>
      {React.Children.map(children, child => {
        const drawer = React.cloneElement(child.props.children[0], {
          handleOpen, open
        });
        const expanded = React.cloneElement(child.props.children[1]);
        return(
          <>
            {drawer}
            {open && expanded}
          </>
        )
      })}
    </li>
  )
}

export default Wrapper
